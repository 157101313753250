import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { ArticlePageQuery } from "../../../../graphql/types"
import { PageProps } from "../../../types/page"
import { mapSourceArticleToView } from "../../../features/articles/mappers/article-mapper"
import { ArticleDetailsPage } from "../../../features/articles/components/article-details-page/article-details-page"
import { Page } from "../../../components/page/page"
import { PageBreadcrumbs } from "../../../core/breadcrumbs"
import { mapProductListToRelatedProductsView } from "@features/store/mappers/related-products-mapper"

const hideMetadataUrls = [
  "can-i-be-forgiven-if-ive-had-abortion",
  "what-happens-when-you-die"
]

export const ArticleTemplate: FunctionComponent<
  PageProps<ArticlePageQuery>
> = ({ data, ...props }) => {
  const { slug = "" } = props.pageContext
  const hideMetaDescription = hideMetadataUrls.indexOf(slug) > -1
  const articleData = data?.contentfulArticle
  const { metadata } = articleData || {}

  const relatedProducts = mapProductListToRelatedProductsView(
    data!.contentfulArticle?.relatedProducts
  )

  const article = mapSourceArticleToView(data!)

  const metadataOverrides = {
    title: metadata?.title || article.metadata.title,
    description: hideMetaDescription
      ? undefined
      : metadata?.description || article.metadata.description,
    openGraphImage:
      metadata?.image?.fixed?.src || article.metadata.openGraphImage
  }

  return (
    <Page
      {...props}
      metadata={{ ...article.metadata, ...metadataOverrides }}
      pageOptions={{
        headerVariant: "cream",
        breadcrumbs: [
          ...PageBreadcrumbs.Articles,
          {
            label: article.title,
            link: `${props.uri}`
          }
        ]
      }}
    >
      <ArticleDetailsPage article={article} relatedProducts={relatedProducts} />
    </Page>
  )
}

export default ArticleTemplate

export const query = graphql`
  query ArticlePage($slug: String) {
    contentfulArticle(slug: { eq: $slug }) {
      ...ArticleDetails
    }
  }
`
