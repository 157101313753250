import { getSrc } from "gatsby-plugin-image"
import { ArticlePageQuery, ContentfulTeacher } from "../../../../graphql/types"
import { ArticleDetails, ProductLinkExternal } from "../article-types"
import { truncate } from "../../../core/utils"
import { mapTeachers } from "../../../core/mappers/map-teachers"
import { mapMetadata } from "../../../core/mappers/map-metadata"
import { mapNestedTopics } from "../../../core/mappers/map-nested-topics"

const pullquoteMarker = "[pullquote]"

export const mapSourceArticleToView = (
  query: ArticlePageQuery
): ArticleDetails => {
  const details = query.contentfulArticle!
  const topics = mapNestedTopics(details.primaryTopic)

  const teachers = mapTeachers(details.teachers as Partial<ContentfulTeacher>[])

  let body = details.bodyCopy?.childMarkdownRemark?.rawMarkdownBody || undefined
  const bodyPlainText =
    details.bodyCopy?.childMarkdownRemark?.plainText || undefined

  let extendedCopy = details.extendedCopy
    ?.sort((a: any, b: any) => {
      return a.order - b.order
    })
    ?.map(({ bodyCopy }: any) => {
      const copy = bodyCopy?.childMarkdownRemark?.rawMarkdownBody || undefined

      return copy
    })
    ?.join("\n")
  const extendedCopyPlainText = details.extendedCopy
    ?.sort((a: any, b: any) => {
      return a.order - b.order
    })
    ?.map(({ bodyCopy }: any) => {
      const copy = bodyCopy?.childMarkdownRemark?.plainText || undefined

      return copy
    })
    ?.join("\n")

  const timeToRead = details.extendedCopy
    ? details.extendedCopy.reduce((acc, cur) => {
        const time = cur?.bodyCopy?.childMarkdownRemark?.timeToRead || 0

        return time + acc
      }, 0)
    : details.bodyCopy?.childMarkdownRemark?.timeToRead || 0

  const pullQuote = details?.pullQuote?.childMarkdownRemark
    ? details.pullQuote?.childMarkdownRemark?.rawMarkdownBody
    : null

  if (pullQuote && body) {
    // find the first occurence of the short
    // code and replace with the pullQuote
    body = body.replace(
      pullquoteMarker,
      `<div class="pullquote">${pullQuote}</div>`
    )

    body = body.replaceAll(pullquoteMarker, "")
  } else if (pullQuote && extendedCopy) {
    // find the first occurence of the short
    // code and replace with the pullQuote
    extendedCopy = extendedCopy.replace(
      pullquoteMarker,
      `<div class="pullquote">${pullQuote}</div>`
    )

    // find the rest of the occurences of the short
    // code and replace with an empty string
    extendedCopy = extendedCopy.replaceAll(pullquoteMarker, "")
  }

  let description = bodyPlainText || extendedCopyPlainText

  if (description && details.pullQuote) {
    description = description.replace(pullquoteMarker, " ")
  }

  const title = details.name || ""
  const metadata = mapMetadata(
    details.metadata,
    {
      title,
      description: description && truncate(description),
      image: getSrc(details.headerImage?.metadataImage)
    },
    {
      title: details.pageTitle,
      url: process.env.GATSBY_ROOT_URL
        ? `${process.env.GATSBY_ROOT_URL}/learn/articles/${details.slug}`
        : ""
    }
  )

  const tempProductLinkExternal = {
    title: details.productLinkExternal?.title || "",
    image: details.productLinkExternal?.image?.file?.url || "",
    url: details.productLinkExternal?.url || "",
    author: details.productLinkExternal?.author || ""
  }

  // we don't want to pass along an empty object to our UI. If all keys are falsy, i.e empty strings
  // then we want to return null so it can fail the null check in the attribution component
  const isExternalLinkObjectEmpty = (obj: ProductLinkExternal) =>
    Object.values(obj).every((val) => Boolean(val) === false)

  const productLinkExternal = isExternalLinkObjectEmpty(tempProductLinkExternal)
    ? null
    : tempProductLinkExternal

  return {
    id: details.id || "",
    contentfulId: details.contentful_id || "",
    title,
    body,
    extendedCopy,
    timeToRead,
    image: details.headerImage?.gatsbyImageData,
    topics,
    teachers,
    slug: details.slug || "",
    date: details.date,
    type: details.articleType!,
    showTabletalkAttribution: details.showTabletalkAttribution || false,
    metadata,
    productLinkExternal,
    primaryTopic: details.primaryTopic
  }
}
